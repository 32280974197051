/* Style for the selected day */
.CalendarDay__selected {
  background-color: #429ac9 !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 6px !important;
}

.CalendarDay__default {
  border-radius: 6px !important;
}

.CalendarDay__highlighted_calendar {
  background-color: rgba(241, 253, 255, 0.669);
  border-radius: 6px;
}

.CalendarDay__highlighted_calendar:hover {
  background-color: rgba(119, 193, 248, 0.669);
  border-radius: 6px;
}