.DayPicker {
  margin: auto;
}
.CalendarDay__default {
  border: none;
}
.CalendarMonth_table thead,
.CalendarMonth_table tbody,
.CalendarMonth_table tfoot {
  border: none;
}
.DayPickerNavigation_button__default {
  border: none;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  border: none;
  background: #1675E0;
}
.CalendarDay__default {
  border-radius: 100%;
}
.CalendarDay__default:hover {
  border: none;
}