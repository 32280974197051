@import "~rsuite/styles/index.less";
@import "dashboard.less";

// rsuite HTML styles reset
@enable-css-reset: false;
// @primary-color: #428ac9;
@primary-color: #1675e0;

body {
  color: #696969;
}

p {
  font-size: 16px;
}

button.rs-btn:focus {
  outline: medium none;
}

// #root {
//   overflow-y: scroll;
// }

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rs-input,
.rs-picker-search input,
.rs-picker-search input:focus {
  margin: initial;
  box-shadow: none;
}

.rs-nav-default .rs-nav-item:focus {
  background: none;
}

.rs-carousel, .rs-carousel-slider-item {
  background-color: transparent;

  .rs-carousel-toolbar {
    bottom: 2px;

    ul {
      @apply tw-rounded-xl;
      background-color: rgba(39, 44, 54, 0.4);
    }
  }
}

.rs-radio-tile {
  border: 1px solid #eaeaea;

  .rs-radio-tile-label {
    color: #696969 !important;
  }
}

.rs-radio-tile-checked, .rs-radio-tile:hover:not(.rs-radio-tile-disabled) {
  border: 1px solid #1675e0;
}

.rs-picker-select-menu .rs-picker-select-menu-items {
  font-size: 14px;
}

.custom-input-number {
  .rs-input {
    text-align: center;
  }

  .rs-input-number-btn-group-vertical {
    display: none;
  }

  .rs-input {
    background-color: #ffffff;
  }
}

.user_management_height {
  height: calc(100svh - 310px);
}

.bottom-mobile-navbar {
  @apply tw-fixed tw-bottom-0 tw-left-0 tw-hidden sm:tw-block tw-w-full tw-py-4 tw-px-8 tw-text-center tw-bg-[#fefefe] tw-border tw-border-solid tw-border-gray-300 tw-rounded-t-md;
}

.rs-slider-graduator {
  position: absolute;
  left: -17px;
}

.user-review-wrapper {
  @apply tw-rounded-xl tw-p-6;
  background-color: #d9e8f4;
}

// Quill WYSIWIG Form custom setting
.quill {
  .ql-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #e5e5ea;
  }

  .ql-container {
    font-size: 14px;
    border: 1px solid #e5e5ea;

    p {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-size: 14px;
    }

    ul,
    ol {
      padding-left: 0;
    }

    li {
      font-size: 14px;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
    }
  }
}

.text-shadow {
  text-shadow: 1px 2px black;
}

@media screen and (max-width: 440px) {
  ul#nps-score.rs-rate.rs-rate-md  {
    text-align: center;
    li.rs-rate-character {
      margin-right: 4px;
      width: auto;
    }
    .rs-rate-md.rs-rate-character {
      width: 0;
    }
  }
}
