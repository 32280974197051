#discover-search {
  position: relative;
}

#disc-search-icon {
  position: absolute;
  height: 45px;
  width: 45px;
  top: 30px;
  left: 15px;
  bottom: 0;
  margin: auto;
}

.result-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-tag {
  cursor: pointer;
  display: inline-block;
  flex: 0 0 auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

#discover-search-input:focus {
  border: 2px solid #1675e0;
}

#search-tag-wrapper {
  display: block;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.text-grey {
  color: #adb5bd;
}

.header {
  color: #4a4a4a;
  font-family: Montserrat;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 0px;
}
.rotated {
  transform: rotate(90deg);
  transform-origin: center center;
  transition: transform 0.25s ease;
}

.ct-tag {
  background-color: #fff;
  margin: 5px 5px 5px 0;
  border-radius: 16px;
  font-size: 0.75rem;
  white-space: nowrap;
  word-wrap: break-word;
  padding: 7.5px 10px;
  color: #1675e0;
  border: 1px solid #1675e0;
  font-weight: 500;
}

.ct-tag:hover {
  background-color: #1675e0;
  color: #fff;
}

.circular-tag {
  background-color: #428ac9;
  margin: 5px 5px 5px 0;
  border-radius: 16px;
  font-size: 0.75rem;
  white-space: nowrap;
  word-wrap: break-word;
  white-space: normal;
  padding: 7.5px 10px;
  color: white;
}

.change-column .mentor_name {
  margin: 5px 0px;
}

.change-row .mentor_name {
  font-size: 1rem !important;
  margin-bottom: 0px !important;
}

.change-row .mentor_link_handle {
  position: relative;
  left: 2.5px;
}

.change-column .mentor_info {
  text-align: "";
}

.change-column .mentor_description {
  display: none;
}

.change-row .mentor_info {
  text-align: left !important;
}

.change-row .mentor_details span {
  white-space: normal !important;
  padding: 2px 0;
}

.change-row .name-position span {
  white-space: normal !important;
  margin-bottom: 5px;
}

.change-row .mentor_cards {
  height: 200px !important;
  min-height: 0px !important;
}

.change-row .mentor_description {
  display: block !important;
}

.change-row .mentor_industries_tags {
  display: none !important;
}

.change-column .mentor_industries_tags {
  display: block !important;
}

.change-row .mentor_details {
  margin: 0 !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.change_row .mentor_profile_photo {
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}

@media only screen and (min-width: 320px) and (max-width: 481px) {

  #search-tag-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-wrap {
  flex-shrink: 0 !important;
}
