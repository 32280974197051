// General
.carousel-dashboard {
  @apply tw-overflow-hidden tw-rounded-lg;
  height: 290px;
}

.follow-banner {
  @apply tw-mb-8 tw-overflow-hidden tw-rounded-lg;
  content: url("../images/follow-mentor-desktop.png");
}

.section-header {
  @apply tw-text-xl tw-font-bold tw-m-0;
  display: inline !important;
  color: #222222;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.promo-banner {
  @apply tw-mb-7 tw-rounded-lg tw-flex tw-items-center tw-cursor-pointer tw-w-full tw-text-base;
  background: #bee1da;
  color: #289a85;
  border: 1px solid #289a85;
  box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.07);

  .rs-message-container {
    @apply tw-py-0;
    width: inherit !important;
  }
}

.lang-banner {
  @apply tw-mb-8 tw-overflow-hidden tw-rounded-lg;
  content: url("../images/lang-desk-tutorial.png");
}

// Search Box
.discover-form {
  display: flex;
}

.discover-search-input {
  width: 100%;
  max-width: 40%;
}

.discover-button {
  width: 35%;
}

// Dashboard Profile
.profile-wrapper {
  @apply tw-flex;
}

.profile-greet {
  @apply tw-text-base tw-mb-1;
  color: #222222;
}

.profile-header {
  @apply tw-pb-3 tw-text-3xl tw-m-0;
  color: #222222;
}

.profile-sub-header {
  @apply tw-text-sm tw-mb-5;
}

.rs-btn.rs-btn-primary {
  background: #428ac9;
}

.rs-btn.rs-btn-ghost {
  color: #428ac9;
  border-color: #428ac9;
}

.profile-links {
  @apply tw-text-base;
  color: #428ac9;
}

.profile-completion-wrapper {
  @apply tw-border-solid tw-border tw-py-2 tw-px-3 tw-border-gray-300 tw-rounded-lg tw-inline-flex tw-items-center;
}

.profile-progress-bar {
  @apply tw-px-0 tw-w-72;
}

// Dashboard Credit
.dash-credit-wrapper {
  @apply tw-text-center;
}

.credit-wrapper {
  @apply tw-w-full;
}

// Dashboard Booking
.dash-booking-card {
  @apply tw-px-3 tw-py-4 tw-border-solid tw-border tw-border-gray-300 tw-rounded-lg tw-mr-5 tw-flex;
  width: 320px;
  max-width: 320px;
}

// Dashboard Event
.dash-event-card {
  @apply tw-mb-2 tw-overflow-hidden tw-border-solid tw-border tw-border-gray-300 tw-rounded-lg tw-mr-5 tw-relative;
  width: 300px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: auto;
}

.dash-event-see-all-card {
  @apply tw-border-solid tw-flex tw-flex-col tw-border tw-border-gray-300 tw-rounded-lg tw-mr-5 tw-w-40 tw-justify-center tw-text-center;
  height: 415.22px;
}

// Dashboard Impact
.impact-cards {
  @apply tw-p-2 tw-border-solid tw-border tw-border-gray-300 tw-rounded-lg tw-text-center;
  width: 120px;
  min-height: 100px;
  max-width: 120px;
}

// Dashboard Mentees / Dashboard Mentors
.user-wrapper {
  @apply tw-border-solid tw-border tw-border-gray-300 tw-rounded-lg tw-mb-5 tw-mx-3 tw-w-64 xs:tw-w-72;

  .user-card {
    @apply tw-w-full tw-flex tw-flex-grow;
  }

  .profile-image-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    border-radius: 6px;
  }

  .new-match-tag {
    content: url('../images/new-match-label-tag.png');
    position: absolute;
    top: -5px;
    left: -9px;
    z-index: 1;
  }

  .profile-badges-tag {
    position: absolute;
    bottom: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
}

.user-see-all-wrapper {
  @apply tw-border-solid tw-flex tw-flex-col tw-border tw-border-gray-300 tw-rounded-lg tw-mx-3 tw-w-40 tw-justify-center tw-text-center;
  height: 548.89px;
}

// Mobile screen
@media screen and (max-width: 420px) {
  // General
  .carousel-dashboard {
    height: 142px;
  }

  .user-see-all-wrapper {
    height: 579.2px !important;
  }

  // Dashboard Mentees / Dashboard Mentors
  .user-wrapper {

    .user-card {
      @apply tw-flex-col;
    }

    .user-profile {
      width: 70px;
    }
  }

  .follow-banner {
    @apply tw-mb-8 tw-overflow-hidden tw-rounded-lg;
    content: url("../images/follow-mentor-mobile.png");
  }

  .section-header {
    @apply tw-text-base;
  }

  .lang-banner {
    content: url("../images/lang-mobile-tutorial.png");
  }

  // Search box
  .discover-search-input {
    width: 100%;
    max-width: 100%;
    margin: 6px 0;
  }

  .discover-button {
    width: 100%;
  }

  .discover-form {
    display: block;
  }

  // Dashboard Profile
  .profile-wrapper {
    flex-wrap: wrap;
  }

  .profile-greet {
    @apply tw-m-0 tw-text-xs;
    color: #222222;
  }

  .profile-header {
    @apply tw-py-0 tw-text-lg tw-mb-1;
    color: #222222;
  }

  .profile-sub-header {
    @apply tw-text-xs;
  }

  .profile-completion-wrapper {
    @apply tw-block tw-border-none tw-p-0 tw-mt-2;
  }

  .profile-links {
    @apply tw-text-sm;
    color: #428ac9;
  }

  .profile-progress-bar {
    @apply tw-w-48;
  }

  // Dashboard Credit
  .dash-credit-wrapper {
    @apply tw-w-full tw-text-left tw-rounded-lg;
    overflow: hidden;
  }

  .credit-wrapper {
    @apply tw-mb-0 tw-p-0;
  }

  .credit-content {
    @apply tw-flex tw-justify-end tw-items-center;

    .credit-header {
      @apply tw-m-0 tw-font-normal;
    }
  }

  // Dashboard Booking
  .dash-booking-card {
    max-width: 285px;
  }

  // Dashboard Event {
  .dash-event-card {
    width: 235px;
  }

  // Dashboard Impact
  .impact-cards {
    max-width: 120px;
  }

}
